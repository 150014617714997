<template>
    <div class="mesgs animated-fast fadeInUp " :class="{whirl : updating}">
        <div class="msg_history" ref="chatHistory">

            <div class="conversation-stated-msg text-center position-relative h-100">
                <span class="badge badge-pill mb-3">{{ $t('chat.component.messages.covnersation_started') }}</span>
            </div>

            <div v-for="(message, index) in messages" :key="index">
                <template v-if="message.sender.id === $store.getters.user('id')">
                    <div class="outgoing_msg">
                        <div class="sent_msg">
                            <vue-simple-markdown :horizontal-line="false" :highlight="false" :table="false" :source="message.body"></vue-simple-markdown>
                            <span class="time_date">{{$moment.utc(message.created_at).fromNow()}}</span></div>
                    </div>
                </template>
                <template v-else>
                    <div class="incoming_msg">
                        <div class="incoming_msg_img"><img :src="message.sender.avatar_url" alt="pic" width="64px" class="rounded"></div>
                        <div class="received_msg">
                            <div class="received_with_msg">
                                <vue-simple-markdown :horizontal-line="false" :highlight="false" :table="false" :source="message.body"></vue-simple-markdown>
                                <span class="time_date">{{$moment.utc(message.created_at).fromNow()}}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="type_msg" :class="{whirl : loading}">
            <div class="input_msg_write">
                <textarea  type="text" class="write_msg" placeholder="Type a message" v-model="output" v-on:keypress.enter="handleEnter"></textarea>
                <button class="msg_send_btn" type="button" v-on:click="sendMessage"><i class="fad fa-paper-plane" aria-hidden="true"></i></button>
            </div>
        </div>
    </div>
</template>
<style>
    .markdown-body::before,
    .markdown-body::after {
        line-height: 0;
    }

    .markdown-body strong {
        font-weight: 700 !important;
    }
</style>
<script>

    import VueSimpleMarkdown from '../../../../node_modules/vue-simple-markdown/src/vue-simple-markdown.component.vue'
    // You need a specific loader for CSS files like https://github.com/webpack/css-loader
    import 'vue-simple-markdown/dist/vue-simple-markdown.css'

    export default {
        components: {
            VueSimpleMarkdown
        },
        data() {
            return {
                messages: [],
                output: '',
                broadcaster: {},
                loading: false,
                updating: true,
                scrollDown: true,
                conversationId: 0,
            }
        },
        beforeMount() {
            this.loadMessages(this.$route.params.conversationId);
        },
        beforeRouteUpdate (to, from, next) {
            this.loadMessages(to.params.conversationId);
            next();
        },
        updated() {
            if (this.scrollDown && this.$refs.chatHistory) {
                //todo: polyfill for edge
                if ('scrollBehavior' in document.documentElement.style) {
                    this.$refs.chatHistory.scrollTo(0, this.$refs.chatHistory.scrollHeight);
                }
                this.scrollDown = false;
            }
        },
        methods: {
            handleEnter(event) {
                if (!event.shiftKey) {
                    event.preventDefault();
                    this.sendMessage();
                }
            },
            sendMessage() {
                if (this.output.length > 0 && !this.loading) {
                    this.loading = true;
                    this.$api.post('chat/conversations/' + this.conversationId + '/messages', {message: {body: this.output}}).then((response) => {
                        this.messages.push(response.data);
                        this.loading = false;
                        this.scrollDown = true;
                    });
                    this.output = '';
                }
            },
            loadMessages(conversationId) {
                this.conversationId = conversationId;
                this.updating = true;
                this.$api.get('chat/conversations/' + conversationId + '/messages').then((response) => {
                    this.messages = response.data;
                    this.updating = false;
                    this.scrollDown = true;

                    this.$title.set('Conversation');

                    this.$api.messageListener(message => {
                        if (message.conversation_id === conversationId) {
                            this.$api.get('chat/conversations/' + message.conversation_id + '/message/' + message.id).then((response) => {
                                this.messages.push(response.data);
                            });
                        }
                    });
                });
            }
        }
    }
</script>